
import { defineComponent, reactive, toRefs } from 'vue';
import ErpList from '@/components/ErpList/ErpList.vue';
import useListData from './components/useListData';
import useTimer from '@/hooks/useTimer';
import { erpConfirm, errorMessage, exportFile, loadPage } from '@/utils/publicMethods';
import dayjs from 'dayjs';
interface FormData {
  consigneeShortName: string;
  status: number[];
  declarationDate: null | string[];
  createdTimeStart: null | string; //创建时间
  createdTimeEnd: null | string;
}
export default defineComponent({
  name: 'declareList',
  components: { ErpList },
  props: {},
  setup() {
    const { startTimer, timeStatus } = useTimer();
    const { startTimer: downTimer, timeStatus: downStatus } = useTimer();
    const { columnList, tableRef } = useListData();
    // 弹窗展示对象
    const dialog = reactive({
      visible: false,
    });
    // 下载资方报单表的日期
    const formData: FormData = reactive({
      consigneeShortName: '',
      status: [4],
      declarationDate: null,
      createdTimeStart: null, //创建时间
      createdTimeEnd: null,
    });
    const customParams = reactive({});
    // 刷新列表
    const refreshTable = (status = false) => {
      tableRef.value.refreshTable(status);
    };
    const showDialog = () => {
      formData.consigneeShortName = '';
      formData.declarationDate = null;
      dialog.visible = true;
    };
    const dateChange = (e) => {
      if (e) {
        formData.createdTimeStart = dayjs(e[0]).format('YYYY-MM-DD') as any;
        formData.createdTimeEnd = dayjs(e[1]).format('YYYY-MM-DD') as any;
      } else {
        formData.createdTimeStart = null;
        formData.createdTimeEnd = null;
      }
    };
    const downLoadFile = () => {
      if (formData.consigneeShortName === '' || formData.consigneeShortName === null) {
        errorMessage('请输入抬头');
      } else if (formData.declarationDate === null) {
        errorMessage('请输入报单日期');
      } else {
        if (downStatus.value === true) {
          downTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/declare/downloadDeclare',
            data: formData,
          }).then(() => {
            dialog.visible = false;
          });
        } else {
          errorMessage('下载的间隔请勿小于30秒');
        }
      }
    };
    const exportFileFn = () => {
      if (timeStatus.value === true) {
        erpConfirm('确定导出吗？').then(() => {
          startTimer();
          exportFile({
            type: 'POST',
            url: '/malicrm/declare/exportDeclare',
            data: tableRef.value.getQueryParams(),
          });
        });
      } else {
        errorMessage('导出的点击间隔请勿小于30秒');
      }
    };

    // 查看详情
    const rowClick = (row, column) => {
      if (column.label === '报单编号') {
        loadPage('declareDetail', {
          id: row.id,
        });
      }
    };

    return {
      showDialog,
      downLoadFile,
      customParams,
      exportFileFn,
      dialog,
      ...toRefs(dialog),
      columnList,
      tableRef,
      refreshTable,
      rowClick,
      formData,
      dateChange,
    };
  },
});
