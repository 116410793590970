import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref } from 'vue';
export default () => {
  const tableRef = ref();
  const columnList: ErpTableOptions[] = [
    {
      label: '报单编号',
      prop: 'declareNo',
      minWidth: 140,
      value: '',
      type: 'input',
      component: defineComponent({
        template: '<div class="record__link">{{filterName(row.declareNo)}}</div>',
        props: ['row', 'col', 'column'],
        emits: ['noop'],
      }),
    },
    {
      label: '销售合同号',
      prop: 'contractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '报单状态',
      prop: 'status',
      propDesc: 'statusDesc',
      minWidth: 130,
      value: [],
      type: 'multiSelect',
      options: 'declare_status',
    },
    {
      label: '抬头',
      prop: 'shortName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '供应商',
      prop: 'supplierName',
      propDesc: 'supplierName',
      minWidth: 200,
      value: '',
      type: 'input',
    },
    {
      label: '大类',
      prop: 'category',
      propDesc: 'categoryDesc',
      minWidth: 90,
      value: [],
      type: 'multiSelect',
      options: 'goods_category_label',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDesc',
      minWidth: 130,
      value: '',
      type: 'input',
    },
    {
      label: '厂号',
      prop: 'plantNo',
      propDesc: 'plantNo',
      minWidth: 128,
      value: '',
      type: 'input',
    },
    {
      label: '目的港',
      prop: 'arrivalPort',
      minWidth: 114,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 152,
      value: '',
      type: 'input',
    },
    {
      label: '业务类型',
      prop: 'businessType',
      propDesc: 'type',
      minWidth: 128,
      value: [],
      options: 'business_type',
      type: 'multiSelect',
    },
    {
      label: '产品',
      prop: 'nameCn',
      minWidth: 152,
      value: '',
      type: 'input',
    },
    {
      label: '柜数',
      prop: 'containerCount',
      minWidth: 90,
      value: [],
      type: 'number',
      filterProp: ['containerMinCount', 'containerMaxCount'],
    },
    {
      label: '总货值',
      prop: 'containerTotalValueDesc',
      minWidth: 152,
      value: [],
      type: 'number',
      filterProp: ['containerMinValue', 'containerMaxValue'],
    },
    {
      label: '交易币种',
      prop: 'tradeCurrency',
      propDesc: 'tradeCurrencyDesc',
      minWidth: 120,
      value: [],
      type: 'multiSelect',
      options: 'trade_currency',
    },
    {
      label: '客户经理',
      prop: 'clientManager',
      minWidth: 152,
      value: '',
      type: 'input',
    },
    {
      label: '采购',
      prop: 'purchase',
      minWidth: 152,
      value: '',
      type: 'input',
    },
    {
      label: '创建人',
      prop: 'creatorName',
      minWidth: 152,
      value: '',
      type: 'input',
    },
    {
      label: '创建时间',
      prop: 'createdTime',
      minWidth: 152,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
    },
    {
      label: '报单成功时间',
      prop: 'declareSuccessTime',
      minWidth: 152,
      value: [],
      type: 'time',
      filterProp: ['declareSuccessTimeStart', 'declareSuccessTimeEnd'],
    },
  ];
  return { columnList, tableRef };
};
